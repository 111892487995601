import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
// import PageTransition from 'gatsby-plugin-page-transitions';

import '../styles/main.scss'


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title 
          }
        }
      }
    `}
    render={data => (
      <>
        {/* <PageTransition
          defaultStyle={{
            transition: 'opacity 500ms ease-in',
            opacity: '0'
          }}
          transitionStyles={{
            entering: { opacity: '1' },
            entered: {  opacity: '1'},
            exiting: {  opacity: '1'},
            exited: { opacity: '0'}
          }}
          transitionTime={500}
          > */}

          {children}
          
          <footer className="footer">
            <span>© 2020 Rob DiMarzo</span>
            <div className="footer__social">
              <a className="social--twitter" href="https://twitter.com/robdimarzo">
                <i className="fab fa-twitter"></i>
                Twitter
              </a>
              <a className="social--codepen" href="https://codepen.io/robdimarzo/">
                <i className="fab fa-codepen"></i>
                Codepen
              </a>
              <a className="social--linkedin" href="https://www.linkedin.com/in/robdimarzo/">
                <i className="fab fa-linkedin"></i>
                LinkedIn
              </a>
            </div>
          </footer>
        {/* </PageTransition> */}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
