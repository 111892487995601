import React from 'react';
// import PropTypes from 'prop-types';
// import { Text } from './logo.css';

const Logo = function Logo() {

  return (
    <svg className="logo" width="200" height="63" viewBox="0 0 90 63" xmlns="http://www.w3.org/2000/svg">
      <g className="logo__wrap" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g className="logo__outline" stroke="#000" strokeWidth="1.40250003">
          <g className="logo__paths">
            <path id="d" d="M12 1h19.0675785c33.3636925 0 29.3382773 37.5803829 0 37.5803829L50.2355936 62h8.8665847c39.9176812 0 39.816809-61-.021037-61H12z" strokeLinejoin="round"/>
            <path id="r3" d="M12 1h19.2443603c33.6730182 0 29.610282 37.5803829 0 37.5803829L50.5900883 62"/>
            <path id="r2" d="M7 1h19.2443603c33.6730182 0 29.610282 37.5803829 0 37.5803829L45.5900883 62" strokeLinejoin="round"/>
            <path id="r1" d="M1 1h19.2443603c33.6730182 0 29.1257569 37.5803829 0 37.5803829L39.5900883 62" strokeLinejoin="round"/>
          </g>
        </g>
      </g>
      <filter id="logoFocus">
        <feDropShadow stdDeviation="5 5" in="SourceGraphic" dx="00" dy="0" floodColor="#4A90E2" floodOpacity="1" result="dropShadow1"/>     
      </filter>
    </svg>
  )
}


export default Logo;
