import React from 'react'
import { Link } from 'gatsby'
import Logo from './logo'
import { DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function toggleMenu() {
  var navButton = document.querySelector('.nav__menubutton');
    let expanded = navButton.getAttribute('aria-expanded') === 'true' || false;
    navButton.setAttribute('aria-expanded', !expanded);
}


const Header = ({ siteTitle }) => ( 
  <header className="site__header">
      <Link to="/" className="header__logo">
        <Logo/>
        <span className="header__name">
          {siteTitle}
        </span>
      </Link>
      <nav className="header__nav">
        <button className="nav__menubutton btn btn--flat" aria-expanded="false" onClick={toggleMenu}>
          <i className="fal fa-bars"></i>
        </button>
        <div className="nav__items" >
          {/* <a to="works" spy={true} smooth={"easeOutCubic"} duration={400} onClick={toggleMenu}>Works</a> */}
          <Link to="/#works" onClick={toggleMenu}>Works</Link>
          <Link to="/about">About</Link>
        </div>
      </nav>
      
  </header>
)


export default Header
